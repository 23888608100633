import { useEffect, useState, React } from "react";
import { Builder, builder } from "@builder.io/react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

Builder.registerComponent(Navlinks, { 
    name: 'Navlinks'
  })

function Navlinks() {
  const [links, setLinks] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const colors = useSelector((state) => state.colors)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Get the CMS data from Builder
  useEffect(() => {
    async function fetchContent() {
      const links = await builder.getAll("navigation-links");
      setLinks(links[0].data.links);
    }
    fetchContent();
  }, []);

  return (
    <AppBar 
        position="static"
        sx={{bgcolor: colors["Navbar Background"]}}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              // color={colors["Navbar Text"]}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                  display: { xs: 'block', md: 'none' },
                }}
            >
              {links.map((link) => (
                  <MenuItem href={link.url} key={link.url}><a href={link.url}>{link.text}</a></MenuItem>
                  ))}
            </Menu>
          </Box>
          <a href="/">
            <Box 
                component="img"
                src="/static/ua.png"
                sx={{
                    width:100
                }}
            />
          </a>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {links.map((link) => (
              <Button
                key={link.url}
                href={link.url}
                sx={{ my: 2, color: colors["Navbar Text"], display: 'block' }}
              >
                {link.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navlinks