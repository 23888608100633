import { createSlice } from "@reduxjs/toolkit";

export const colorSlice = createSlice({
    name: 'color',
    initialState: {},
    reducers: {
        populate: (state, action) => {
            action.payload.forEach((color)=>{
                state[color.name] = color.data.color
            })
        }
    }
})

export const { populate } = colorSlice.actions

export default colorSlice.reducer