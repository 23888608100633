
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { useDispatch } from "react-redux";
import { populate } from "./redux/colorSlice";
import Navlinks from "./Navlinks";

// Put your API key here
builder.init("c459195a9efb4a29b79013e286defec8");

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
  const dispatch = useDispatch()
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  // get the page content from Builder
   useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname
        })
        .promise();

      setContent(content);
      setNotFound(!content);

      const colors = await builder.getAll("colors")
        dispatch(populate(colors))

      // if the page title is found, 
      // set the document title
      if (content?.data.title) {
       document.title = content.data.title
      }
    }
    fetchContent();
  }, [dispatch]);
  
  // If no page is found, return 
  // a 404 page from your code.
  // The following hypothetical 
  // <FourOhFour> is placeholder.
  // if (notFound && !isPreviewingInBuilder) {
  //   return <FourOhFour/>
  // }

  // return the page when found
  return (
    <>
      <BuilderComponent model="page" content={content} />
    </>
  );
}